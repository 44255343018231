export const accountLevel = (transaction: number) => {
  if (transaction > 350) {
    return "Elite";
  } else if (transaction > 200) {
    return "Pro";
  } else if (transaction > 100) {
    return "Premium";
  } else if (transaction > 50) {
    return "Advanced";
  } else if (transaction > 0) {
    return "Starter";
  } else {
    return "Inactive";
  }
};
