/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Item } from "../utils/ItemStyle";
import { InputText } from "../Components/TextFields/InputText";
import { LoadingButton } from "@mui/lab";
import ButtonComp from "../Components/Buttons/ButtonComp";
import axiosInstances from "../hooks/axiosInstances";
import { useColors } from "../utils/useColor";
import {
  fetchTransactions,
  fetchUserBalance,
  fetchDepositedAmount,
  fetchExpensesAmount,
} from "../utils/constants";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../utils/formSlice";
import { TransactionList } from "../Components/others/TransactionList";
import useUser from "../lib/useUser";
import { IPinsType } from "../types/common";
import EducationTable from "../Components/Tables/EducationTable";

const Jamb = () => {
  const [isLoad, setIsLoad] = useState(false);
  const user = useUser((state) => state.user);
  const [pins, setPins] = useState<IPinsType[]>([]);
  const [name, setName] = useState("");
  const { isLoading, jambVariants, transactions } = useSelector(
    (state: any) => state.form
  );
  const dispatch = useDispatch();
  const { primary } = useColors();

  const formik = useFormik({
    initialValues: {
      variation_code: "",
      billersCode: "",
      serviceID: "jamb",
      phone: "",
      email: "",
      amount: "",
    },
    onSubmit: (values) => {
      dispatch(setLoading(true));
      axiosInstances
        .post("/jamb", values)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          fetchTransactions(dispatch);
          fetchUserBalance(dispatch);
          fetchDepositedAmount(dispatch);
          fetchExpensesAmount(dispatch);
          setName("");
          formik.resetForm();
          fetchPins(user._id);
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setMessage(error.response.data.error));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  const getCandDetails = async (data: any) => {
    setIsLoad(true);
    await axiosInstances
      .post("/jamb/verify", data)
      .then((res) => {
        setName(res.data.name);
        setIsLoad(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPins = async (id?: string) => {
    await axiosInstances
      .get(`pin/${id}`)
      .then((res) => {
        setPins(res.data.pins);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (formik.values.variation_code) {
      const filterAmount = jambVariants.filter(
        (data: any) => data.variation_code === formik.values.variation_code
      );

      formik.setFieldValue("amount", filterAmount[0].variation_amount - 20);
    }

    if (
      formik.values.billersCode &&
      formik.values.billersCode.length === 10 &&
      formik.values.variation_code
    ) {
      const data = {
        billersCode: formik.values.billersCode,
        serviceID: "jamb",
        type: formik.values.variation_code,
      };
      getCandDetails(data);
    }

    if (user.role === "USER") {
      fetchPins(user._id);
    }
  }, [formik.values.variation_code, formik.values.billersCode, user]);

  const filteredTransaction = transactions.filter(
    (item: any) => item.description === "Jamb pin purchase"
  );

  const last5Transactions = filteredTransaction.slice(-5).reverse();
  return (
    <>
      <HeaderTextButton headerName="Jamb Pin" />
      <Box sx={{ flexGrow: 0, mt: "30px" }}>
        <Grid
          container
          spacing={0}
          columns={16}
          gap={{ xs: 2, sm: 1, md: 0 }}
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column", sm: "row" },
          }}
        >
          <Grid xs={16} sm={8} md={8}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Item
                sx={{
                  overflow: "hidden",
                  mr: { lg: "20px", xs: "0px" },
                  p: 0,
                }}
              >
                <Box sx={{ mx: "30px", my: "40px" }}>
                  <Box sx={{ mb: "15px" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.variation_code &&
                        Boolean(formik.errors.variation_code)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Exam Type
                      </InputLabel>
                      <Select
                        id="variation_code"
                        name="variation_code"
                        label="Exam Type"
                        value={formik.values.variation_code}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.variation_code &&
                          Boolean(formik.errors.variation_code)
                        }
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {jambVariants.map((data: any, index: number) => (
                          <MenuItem value={data.variation_code} key={index}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.touched.variation_code &&
                      formik.errors.variation_code && (
                        <Typography
                          color="error"
                          fontSize={"0.75rem"}
                          ml={"14px"}
                        >
                          {formik.errors.variation_code}
                        </Typography>
                      )}
                  </Box>
                  <Box sx={{ mb: name ? "5px" : "15px" }}>
                    <InputText
                      id="billersCode"
                      label="Profile Code"
                      value={formik.values.billersCode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.billersCode &&
                        Boolean(formik.errors.billersCode)
                      }
                      helperText={
                        formik.touched.billersCode && formik.errors.billersCode
                      }
                    />
                  </Box>
                  {name ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left",
                        mb: "10px",
                      }}
                    >
                      <Typography fontWeight="bold">{name}</Typography>
                      <Typography color="error" variant="body2">
                        Verify Candidate name before proceeding
                      </Typography>
                    </Box>
                  ) : (
                    isLoad && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <CircularProgress sx={{ color: primary.dark }} />
                        <Typography>Loading.....</Typography>
                      </Box>
                    )
                  )}
                  <Box sx={{ mb: "15px" }}>
                    <InputText
                      id="phone"
                      label="Phone Number"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      disabled={isLoad}
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Box>
                  <Box sx={{ mb: "15px" }}>
                    <InputText
                      id="email"
                      label="Email Address"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      disabled={isLoad}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Box>
                  <Box sx={{ mb: "5px" }}>
                    <InputText
                      id="amount"
                      label="Amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                      helperText={formik.touched.amount && formik.errors.amount}
                      disabled
                    />
                  </Box>
                  <Typography
                    color="error"
                    variant="body2"
                    sx={{ textAlign: "left", mb: "10px" }}
                  >
                    N20 discount on purchase
                  </Typography>
                  {isLoading ? (
                    <LoadingButton loading variant="outlined" fullWidth>
                      Submit
                    </LoadingButton>
                  ) : (
                    <ButtonComp
                      variant="contained"
                      text="Proceed"
                      type="submit"
                      fullWidth
                      disableElevation
                      disabled={!name}
                    />
                  )}
                </Box>
              </Item>
            </form>
          </Grid>
          <Grid xs={16} sm={8} md={8}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              <Stack sx={{ p: "10px", minHeight: "315px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "black",
                  }}
                >
                  <Typography fontWeight={"bold"}>
                    Transactions History
                  </Typography>
                  <Link
                    to="/transactions"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <Typography fontWeight={"bold"} sx={{ cursor: "pointer" }}>
                      See All
                    </Typography>
                  </Link>
                </Box>
                <Stack sx={{ mt: "20px" }} gap={1}>
                  {last5Transactions ? (
                    last5Transactions.map((data: any, index: number) => (
                      <TransactionList
                        key={index}
                        amount={data.amount}
                        description={data.description}
                        date={data && data.createdAt}
                      />
                    ))
                  ) : (
                    <Typography>No transaction</Typography>
                  )}
                </Stack>
              </Stack>
            </Item>
          </Grid>
        </Grid>

        <Box sx={{ mt: "20px" }}>
          <EducationTable data={pins} />
        </Box>
      </Box>
    </>
  );
};

export default Jamb;
