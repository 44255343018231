import React, { FC, useState } from "react";
import { IPinsType } from "../../types/common";
import {
  styled,
  TableCell,
  Theme,
  tableCellClasses,
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useColors } from "../../utils/useColor";
import { Download } from "@mui/icons-material";
import { formatNumber } from "../../utils/FormatedNumber";
import { convertTimestampToDate } from "../../utils/utils";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ScratchCard from "../Pdf/ScratchCard";

interface Props {
  data: IPinsType[];
}

const EducationTable: FC<Props> = ({ data }) => {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const { success, error } = useColors();

  return (
    <>
      <Box>
        {/* <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: "1rem" }}
        size="small"
      /> */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell align="left">Type</StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>
                <StyledTableCell align="left">Quantity</StyledTableCell>
                <StyledTableCell align="left">Ref_No</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="left">Receipt</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.length > 0 ? (
                data
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{row.cardType}</TableCell>
                      <TableCell align="left">
                        {formatNumber(row.amount)}
                      </TableCell>
                      <TableCell align="left">{row.pin.length}</TableCell>
                      <TableCell align="left">{row.reference_no}</TableCell>
                      <TableCell align="left" width="10%">
                        {row.status === "Successful" ? (
                          <Box
                            sx={{
                              background: success.light,
                              //   width: { lg: "80%", xs: "100%", md: '90%' },
                              borderRadius: "20px",
                              textAlign: "center",
                              color: "#fff",
                              overflow: "hidden",
                              px: { lg: "0px", xs: "5px", md: "17px" },
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              Successful
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              background: error.light,
                              //   width: { lg: "80%", xs: "100%", md: '90%' },
                              borderRadius: "20px",
                              textAlign: "center",
                              color: "#fff",
                              overflow: "hidden",
                              px: { lg: "0px", xs: "5px", md: "17px" },
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              Failed
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {convertTimestampToDate(row.createdAt)}
                      </TableCell>

                      <TableCell align="left" width="10%">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Tooltip title="Download receipt">
                            <Box>
                              <PDFDownloadLink
                                document={<ScratchCard id={row._id} />}
                                fileName={`${row.cardType} Scratch Card(${row.reference_no})`}
                              >
                                <Download
                                  sx={{ cursor: "pointer", color: "green" }}
                                />
                              </PDFDownloadLink>
                            </Box>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default EducationTable;
